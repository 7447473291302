import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
// import Slide from 'react-reveal/Slide';
// import Scroll from 'react-scroll';
// import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { Waypoint } from 'react-waypoint';

import Color from 'styles/Color';
import Screens from 'styles/Screens';
import logger from 'utils/logger';

import {
  Container
} from 'components/GridSystem';

import {
  BigIdea,
  Marker,
  PrettyTitle,
  SectionLabel
} from 'components/Text';

import {
  SectionClients,
  SectionContact,
  SectionLanding,
  SectionServices,
  SectionWho,
  SectionWork
} from 'components/Sections';

import Layout from '../components/Layout'
import SEO from '../components/seo'

class Index extends React.Component {
  render () {
    const {
      data,
      sectionLanding
    } = this.props;
    logger.gql(data);
    const siteTitle = data.site.siteMetadata.title;
  
    const clients = data.allContentfulClient.edges;
    const portfolio = data.allContentfulPortfolio.edges;
    const services = data.allContentfulService.edges;
    const blocks = data.allContentfulBlock.edges;
    const contacts = data.allContentfulContact.edges;

    let heroWho = {};
    let heroServices = {};
    let heroContact = {};

    const seo = {
      title: 'Creative Studio',
      description:
        'We are a creative studio driven by passion for design and detailed aesthetics.',
      keywords: [
        'creative',
        'studio',
        'motion',
        'graphics',
        'design',
        'brand',
        'digital',
        'agency',
        'visual effects',
        'vfx',
        'animation',
        'content',
        'production',
        'multimedia',
        '2D',
        '3D'
      ]
    }

    blocks.forEach((edge) => {
      const { node } = edge;
      // Contact
      if (node.contentful_id === `510ea8KTdpKN2UnG87ErNs`) {
        heroContact = node;
      }
      // Who
      if (node.contentful_id === `4HxccDVL3wr0Q2LueHkb1z`) {
        heroWho = node;
      }
      // Services
      if (node.contentful_id === `1U1VIVtq1gyzSQkDmvPLBU`) {
        heroServices = node;
      }
    });

    return (
      <Layout location={sectionLanding} title={siteTitle}>
        <SEO
          title={`Creative Studio`}
          description={seo.description}
          keywords={seo.keywords}
        />
        <SectionLanding />
        <SectionWho hero={heroWho} />
        <SectionWork portfolio={portfolio} />
        <SectionServices hero={heroServices} services={services} />
        <SectionClients clients={clients} />
        <SectionContact hero={heroContact} contacts={contacts} />
      </Layout>
    )
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulClient(sort: {fields: sort, order: ASC}, limit: 400){
      edges{
        node{
          ... Client
        }
      }
    }
    allContentfulPortfolio(sort: {fields: sort, order: ASC}, limit: 8){
      edges{
        node{
          id
          title
          URL
          vimeoId
          client {
            ... Client
          }
          featuredImage{
            id
            file{
              url
            }
          }
        }
      }
    }
    allContentfulService(sort: {fields: sort, order: ASC}) {
      edges{
        node{
          ... Service
        }
      }
    }
    allContentfulBlock{
      edges{
        node{
          id
          contentful_id
          title
          label
          heading
          content{
            childMdx{
              ... Markdown
            }
          }
        }
      }
    }
    allContentfulContact(sort: {fields: sort, order: ASC}) {
      edges {
        node {
          id
          contentful_id
          title
          name
          email
          phone
          address1
          city
          state
          zip
          country
        }
      }
    }
  }
`
