import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {
  StaticQuery,
  graphql
} from 'gatsby';

function SEO ({
  description,
  lang,
  meta,
  keywords,
  title,
  titleSufix
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description;
        const { siteUrl, seoImg } = data.site.siteMetadata;
        const img = `${typeof window !== 'undefined' && window.location.origin}/${seoImg}`;
        const seoTitle = `${data.site.siteMetadata.title} | ${
          titleSufix ? title : ``
        }`;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={seoTitle}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: seoTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: img,
              },
              {
                property: `og:url`,
                content: siteUrl,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              // {
              //   name: `twitter:creator`,
              //   content: data.site.siteMetadata.author
              // },
              {
                name: `twitter:title`,
                content: seoTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: img,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  titleSufix: true,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  titleSufix: PropTypes.bool
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        seoImg
      }
    }
  }
`;
